import { useAllNetworks } from "backend/resources/network/network"
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon"
import { CarespaceTable } from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/CarespaceTable"
import { CarespaceTableFilters } from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/CarespaceTableFilters"
import { PageContainer } from "components/PageContainer"
import { PageMainHeader } from "components/PageMainHeader"
import { useState } from "react"
import { CarespaceCreationWizard } from "shared/forms/CarespaceCreationWizard/CarespaceCreationWizard"
import { useCarespaceCreationWizard } from "shared/forms/CarespaceCreationWizard/useCarespaceCreationWizard"
import { useCarespaceFilterStore } from "state/carespaceFilter/carespaceFilter"
import { ReactComponent as WarningIcon } from "assets/warning.svg"
import { ResponsiveModal } from "shared/ui/responsive-modal"

export function CarespacesPage() {
  const reset = useCarespaceCreationWizard((state) => state.reset)
  const { networks, isLoading } = useAllNetworks();
  const { org } = useCarespaceFilterStore();

  const [isCreatePopoverOpen, setIsCreatePopoverOpen] = useState<boolean>(false);
  const [showAllOrgWarning, setShowAllOrgWarning] = useState(false);

  const handleAddCarespace = () => {
    if (!org || org === "All") {
      setShowAllOrgWarning(true);
    } else {
      reset();
      setIsCreatePopoverOpen(true);
    }
  };

  const handleAllOrgWarningClose = () => {
    setShowAllOrgWarning(false);
  };

  return (
    <PageContainer>
      <div className="flex items-center gap-5">
        <PageMainHeader text={"Carespaces"} />
      </div>
      <CarespaceTableFilters 
        carespaces={networks}
        isLoading={isLoading}
      />
      <CarespaceTable
        carespaces={networks}
        isLoading={isLoading}
        onAddCarespace={handleAddCarespace}
      />

      {/* ALL Org Filter Warning Modal */}
      <ResponsiveModal
        isOpen={showAllOrgWarning}
        onClose={handleAllOrgWarningClose}
        title="Create New Carespace"
        customWidth="md:w-[480px]"
      >
        <div className="flex flex-col">
          <p className="text-base text-center mb-6">Please select an organization first.</p>
          <ButtonWithIcon
            onClick={handleAllOrgWarningClose}
            text="Close"
            icon={IconOption.CHECKMARK}
            size="small"
            className="self-end"
          />
        </div>
      </ResponsiveModal>

      {/* Carespace Creation Popup */}
      {isCreatePopoverOpen &&
        <CarespaceCreationWizard
          onClose={() => {
            reset()
            setIsCreatePopoverOpen(false)
          }}
        />
      }
    </PageContainer>
  )
}