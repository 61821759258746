import { zodResolver } from "@hookform/resolvers/zod"
import { OrgRoleType, OrgRoleTypeToLabel } from "backend/resources/userRole/types"
import { UseFormReturn, useForm } from "react-hook-form"
import { OrgMemberCombobox } from "shared/forms/CarespaceCreationWizard/OrgMemberAutocomplete"
import {
  Form,
  FormRoot,
  FormSection,
  FormSectionHeader
} from "shared/ui/form"
import { useCarespaceFilterStore } from "state/carespaceFilter/carespaceFilter"
import * as z from "zod"

/**
 * schema
 */

export const orgMemberInnerSchema = z.object({
  organization_id: z.string(),
  org_role: z.string(),
  user_id: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  cell_number: z.string(),
  email: z.string(),
})

export const orgMemberSchema = z.object({
  orgMember: orgMemberInnerSchema.optional(),
  isUsingCraniometrix: z.boolean().optional()
})



/**
 * hook
 */
export const useAddOrgMemberForm = () => useForm<z.infer<typeof orgMemberSchema>>({
  resolver: zodResolver(orgMemberSchema),
  defaultValues: {},
  mode: "onSubmit",
});


export type AddOrgMemberFormHook = UseFormReturn<z.infer<typeof orgMemberSchema>>

/**
 * form
 */
export const addOrgMemberFormId = "add-org-member-form"

export function AddOrgMemberNavigatorForm({ 
  form, 
  roleType, 
  step,
  isUsingCmCareNavigators 
}: { 
  form: UseFormReturn<z.infer<typeof orgMemberSchema>>, 
  roleType: OrgRoleType | undefined, 
  step?: number,
  isUsingCmCareNavigators?: boolean 
}) {
  const { org } = useCarespaceFilterStore()

  // Don't show the form for care navigators when using CM care navigators
  if (isUsingCmCareNavigators && roleType === OrgRoleType.CARE_NAVIGATOR) {
    return null
  }

  if (!org) {
    return null
  }

  return (
    <Form {...form}>
      <FormRoot id={`${addOrgMemberFormId}-${roleType}`}>
        <FormSection>
          <FormSectionHeader title={
            <div className="flex flex-col">
              {step && `Step ${step}:`} Add a {roleType ? OrgRoleTypeToLabel[roleType] : "Org Member"}
            </div>
          }
          />
          <div className="flex flex-col items-center md:items-start gap-4">
            <div className="grid grid-cols-[2fr,3fr] items-center gap-3 w-full">
              <p>Member Name</p>
              <OrgMemberCombobox
                key={roleType}
                form={form}
                orgId={org}
                role_type={roleType}
              />
            </div>
          </div>
          <p className="w-full font-light text-sm">
            Note: if the member is not found, please add them to the
            organization and then you can add them to the Carespace.
          </p>
        </FormSection>
      </FormRoot>
    </Form>
  )
}
