import { DatePicker } from "@mui/x-date-pickers";
import { NetworksWithAdlosAndCaregivers } from "backend/resources/network/network";
import { NetworkRoleType } from "backend/resources/userRole/types";
import { useOrgs } from "backend/resources/orgRole";
import { Sex } from "components/CarespacePage/PAFSubmission/pafEnums";
import { PAFStatusLabelMap } from "components/CarespacePage/Tabs/CarespaceMainTab/constants";
import { CarespaceTableFields, DYAD } from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/constants";
import { getCarespaceNameFromCarespace, getOrganizationNameFromCarespace, getPrimaryCarePhysicianNameFromCarespace, getUserRoleMapFromCarespace } from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/util";
import { Select } from "components/Select";
import { TaskFilter } from "components/TaskNavigatorPage/TaskFilters";
import { FC, useMemo } from "react";
import { useCarespaceFilterStore } from "state/carespaceFilter/carespaceFilter";

interface CarespaceTableFiltersProps {
  carespaces: NetworksWithAdlosAndCaregivers[];
  isLoading: boolean;
}

const DEFAULT_OPTION = { value: "All", label: "All" };
const TBD_STATUS = "TBD";

export const CarespaceTableFilters: FC<CarespaceTableFiltersProps> = ({ carespaces }) => {
  const { org, dob, sex, dyad, doctor, pcg, cn, pcp, status, carespace, setOrg, setDob, setSex, setDyad, setDoctor, setPcg, setCn, setPcp, setStatus, setCarespace } = useCarespaceFilterStore();
  const { ownOrgIdentities } = useOrgs();

  const organizations = useMemo(() => {
    // Create a map to deduplicate by name
    const orgMap = new Map<string, { value: string, label: string }>();
    
    ownOrgIdentities
      ?.filter(org => org.organization?.name?.trim())
      .forEach(org => {
        const name = org.organization?.name || '';
        // Only add if we haven't seen this name before
        if (!orgMap.has(name)) {
          orgMap.set(name, {
            value: org.organization_id,
            label: name
          });
        }
      });

    // Convert map to array and sort
    return Array.from(orgMap.values())
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];
  }, [ownOrgIdentities]);

  const getDefaultOptionMap = () => {
    return { [DEFAULT_OPTION.label]: DEFAULT_OPTION };
  }

  const getCurrentOption = (
    optionValue: string | undefined, 
    options?: Array<{ value: string, label: string }>
  ): { value: string | undefined, label: string } => {
    if (options) {
      return optionValue ? options.find(o => o.value === optionValue) ?? options[0] : options[0];
    }
    return { value: optionValue ?? undefined, label: optionValue ?? "All" };
  }

  // Filter carespaces based on selected organization
  const filteredCarespaces = useMemo(() => {
    if (!org || org === "All") return carespaces;
    return carespaces.filter(cs => cs.organization_id === org);
  }, [carespaces, org]);

  // Get carespace options from filtered carespaces
  const carespaceOptions = useMemo(() => {
    const options = [DEFAULT_OPTION];
    const uniqueCarespaces = new Set();
    
    filteredCarespaces.forEach(cs => {
      const name = getCarespaceNameFromCarespace(cs);
      if (!uniqueCarespaces.has(name) && name) {
        uniqueCarespaces.add(name);
        options.push({ value: name, label: name || '' });
      }
    });
    
    return options;
  }, [filteredCarespaces]);

  // build filter options
  const buildFilterOptions = () => {
    const orgOptions = [
      { value: "All", label: "All" } as const,
      ...organizations
    ];

    const caregiverOptions: Record<string, { value: string; label: string }> = getDefaultOptionMap();
    const doctorOptions: Record<string, { value: string; label: string }> = getDefaultOptionMap();
    const careNavigatorOptions: Record<string, { value: string; label: string }> = {
      ...getDefaultOptionMap(),
      [TBD_STATUS]: { value: TBD_STATUS, label: TBD_STATUS }
    };
    const pcpOptions: Record<string, { value: string; label: string }> = getDefaultOptionMap();

    // iterate through filtered carespaces and add keys to the option maps
    filteredCarespaces.forEach((cs) => {
      const pcpName = getPrimaryCarePhysicianNameFromCarespace(cs);
      const userRolesMap = getUserRoleMapFromCarespace(cs);
      const caregiverName = userRolesMap[NetworkRoleType.PRIMARY_CAREGIVER];
      const doctorName = userRolesMap[NetworkRoleType.DOCTOR];
      const careNavigatorName = userRolesMap[NetworkRoleType.CARE_NAVIGATOR];

      if (pcpName) pcpOptions[pcpName] = { value: pcpName, label: pcpName };
      if (caregiverName) caregiverOptions[caregiverName] = { value: caregiverName, label: caregiverName };
      if (doctorName) doctorOptions[doctorName] = { value: doctorName, label: doctorName };
      if (careNavigatorName) careNavigatorOptions[careNavigatorName] = { value: careNavigatorName, label: careNavigatorName };
    });

    return {
      orgOptions,
      caregiverOptions: Object.values(caregiverOptions),
      doctorOptions: Object.values(doctorOptions),
      careNavigatorOptions: Object.values(careNavigatorOptions),
      pcpOptions: Object.values(pcpOptions),
      sexOptions: [DEFAULT_OPTION, ...Object.values(Sex).map((sex) => ({ value: sex, label: sex }))],
      dyadOptions: [DEFAULT_OPTION, ...Object.values(DYAD).map((dyad) => ({ value: dyad, label: dyad }))],
      statusOptions: [DEFAULT_OPTION, ...Object.values(PAFStatusLabelMap).map((status) => ({ value: status, label: status }))]
    };
  };

  const { 
    orgOptions,
    caregiverOptions,
    doctorOptions,
    careNavigatorOptions,
    pcpOptions,
    sexOptions,
    dyadOptions,
    statusOptions
  } = buildFilterOptions();

  return (
    <div className={`flex flex-col gap-y-4 w-full`}>
      <div className={`grid grid-cols-3 w-full gap-x-5 gap-y-4`}>
        <TaskFilter label={CarespaceTableFields.ORG}>
          <Select
            currentOption={getCurrentOption(org, orgOptions)}
            options={orgOptions}
            onChange={(value) => {
              setOrg(value === "All" ? undefined : value);
              setCarespace(undefined); // Reset carespace when org changes
            }}
            classNames="w-full"
            isSearchable
            placeHolder="Search organizations..."
          />
        </TaskFilter>
        <TaskFilter label={CarespaceTableFields.CARESPACE}>
          <Select
            currentOption={getCurrentOption(carespace, carespaceOptions)}
            options={carespaceOptions}
            onChange={(carespace) => setCarespace(carespace)}
            classNames="w-full"
            isSearchable
            placeHolder="Search carespaces..."
          />
        </TaskFilter>
        <TaskFilter label={CarespaceTableFields.PCG}>
          <Select
            currentOption={getCurrentOption(pcg, caregiverOptions)}
            options={caregiverOptions}
            onChange={(pcg) => setPcg(pcg)}
            classNames="w-full"
            isSearchable
            placeHolder="Search caregivers..."
          />
        </TaskFilter>
      </div>
      <div className={`grid grid-cols-3 w-full] gap-x-5 gap-y-4`}>
        <TaskFilter label={CarespaceTableFields.STATUS}>
          <Select
            currentOption={getCurrentOption(status, statusOptions)}
            options={statusOptions}
            onChange={(status) => setStatus(status)}
            classNames="w-full"
            isSearchable
            placeHolder="Search statuses..."
          />
        </TaskFilter>
        <TaskFilter label={CarespaceTableFields.SEX}>
          <Select
            currentOption={getCurrentOption(sex, sexOptions)}
            options={sexOptions}
            onChange={(sex) => setSex(sex)}
            classNames="w-full"
            isSearchable
            placeHolder="Search sexes..."
          />
        </TaskFilter>
        <TaskFilter label={CarespaceTableFields.DYAD}>
          <Select
            currentOption={getCurrentOption(dyad, dyadOptions)}
            options={dyadOptions}
            onChange={(dyad) => setDyad(dyad)}
            classNames="w-full"
            isSearchable
            placeHolder="Search dyads..."
          />
        </TaskFilter>
      </div>
      <div className={`grid grid-cols-3 w-full] gap-x-5 gap-y-4`}>
        <TaskFilter label={CarespaceTableFields.DOCTOR}>
          <Select
            currentOption={getCurrentOption(doctor, doctorOptions)}
            options={doctorOptions}
            onChange={(doctor) => setDoctor(doctor)}
            classNames="w-full"
            isSearchable
            placeHolder="Search doctors..."
          />
        </TaskFilter>
        <TaskFilter label={CarespaceTableFields.PCP}>
          <Select
            currentOption={getCurrentOption(pcp, pcpOptions)}
            options={pcpOptions}
            onChange={(pcp) => setPcp(pcp)}
            classNames="w-full"
            isSearchable
            placeHolder="Search PCPs..."
          />
        </TaskFilter>
        <TaskFilter label={CarespaceTableFields.CN}>
          <Select 
            currentOption={getCurrentOption(cn, careNavigatorOptions)}
            options={careNavigatorOptions}
            onChange={(cn) => setCn(cn)}
            classNames="w-full"
            isSearchable
            placeHolder="Search care navigators..."
          />
        </TaskFilter>
      </div>
      <div className={`grid grid-cols-3 w-full] gap-x-5 gap-y-4`}>
        <TaskFilter label={CarespaceTableFields.DOB}>
          <DatePicker
            value={dob}
            onChange={(dob) => {
              // format the date to mm/dd/yyyy
              const formattedDob = dob ? new Date(dob).toLocaleDateString('en-US') : undefined;
              setDob(formattedDob);
            }}
          />
        </TaskFilter>
      </div>
    </div>
  )
}